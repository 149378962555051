export const categoryConstants = {
    GETSUPPLIERS_REQUEST: "GET_SUPPLIERS_REQUEST",
    GETSUPPLIERS_SUCCESS: "GET_SUPPLIERS_SUCCESS",
    GETSUPPLIERS_FAILURE: "GET_SUPPLIERS_FAILURE",
    GETCATFORSUP_REQUEST: "GET_CAT_FOR_SUP_REQUEST",
    GETCATFORSUP_SUCCESS: "GET_CAT_FOR_SUP_SUCCESS",
    GETCATFORSUP_FAILURE: "GET_CAT_FOR_SUP_FAILURE",
    // GETSUBCATFORSUP_REQUEST: "GET_SUBCAT_FOR_SUP_REQUEST",
    // GETSUBCATFORSUP_SUCCESS: "GET_SUBCAT_FOR_SUP_SUCCESS",
    // GETSUBCATFORSUP_FAILURE: "GET_SUBCAT_FOR_SUP_FAILURE",

    GETSUPPFORCMP_REQUEST: "GET_SUPP_FOR_CMP_REQUEST",
    GETSUPPFORCMP_SUCCESS: "GET_SUPP_FOR_CMP_SUCCESS",
    GETSUPPFORCMP_FAILURE: "GET_SUPP_FOR_CMP_FAILURE",

    GETCATFORSUPPCMP_REQUEST: "GET_CAT_FOR_SUPP_CMP_REQUEST",
    GETCATFORSUPPCMP_SUCCESS: "GET_CAT_FOR_SUPP_CMP_SUCCESS",
    GETCATFORSUPPCMP_FAILURE: "GET_CAT_FOR_SUPP_CMP_FAILURE",
}