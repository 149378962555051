export const reportConstants = {
    GETDAILYREPORT_REQUEST: "GET_DAILY_REPORT_REQUEST",
    GETDAILYREPORT_SUCCESS: "GET_DAILY_REPORT_SUCCESS",
    GETDAILYREPORT_FAILURE: "GET_DAILY_REPORT_FAILURE ",

    GETITEMSFORINVOICE_REQUEST: "GET_ITEMS_FOR_INVOICE_REQUEST",
    GETITEMSFORINVOICE_SUCCESS: "GET_ITEMS_FOR_INVOICE_SUCCESS",
    GETITEMSFORINVOICE_FAILURE: "GET_ITEMS_FOR_INVOICE_FAILURE",

    RESET_REDUCER_DETAILS: "RESET_REDUCER_DETAILS"
}