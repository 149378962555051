export const invoiceConstants = {
    "GETID_REQUEST": "GET_ID_REQUEST",
    "GETID_SUCCESS": "GET_ID_SUCCESS",
    "GETID_FAILURE": "GET_ID_FAILURE",

    "SETINVOICEHEADER_REQUEST": "SET_INVOICE_HEADER_REQUEST",
    "SETINVOICEHEADER_SUCCESS": "SET_INVOICE_HEADER_SUCCESS",
    "SETINVOICEHEADER_FAILURE": "SET_INVOICE_HEADER_FAILURE",

    "ADDITEMS_REQUEST": "ADD_ITEMS_REQUEST",
    "ADDITEMS_SUCCES": "ADD_ITEMS_SUCCESS",
    "ADDITEMS_FAILURE": "ADD_ITEMS_FAILURE",

    RESETWRITTENVALUE: "RESET_WRITTEN_VALUE"

}