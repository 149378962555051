export const userPath = {
    login: '/login',
    homePage: '/pocetna',
    shops: '/prodavnice',
    vendorShops: '/korisnikoveProdavnice',
    order: '/katalogProizvoda',
    shoppingCart: '/korpa',
    finance: '/finansije',
    dailyReport: '/dnevniIzvjestaj',
    dailyReportAdmin: '/dnevniIzvjestajAdmin',
    storeSurvey: '/anketaZaProdavnicu',
    storeSurveyAdmin: '/anketaZaProdavnicuAdmin',
    schedule: '/raspored',
    scheduleAdmin: '/rasporedAdmin',
    dailyReportStoreSurvey: '/dnevniIzvjestajAnketa',
    dailyReportStoreSurveyUser: '/dnevniIzvjestajAnketaKomerc'
}