export const articleContants = {
    GETARTICLE_REQUEST :"GET_ARTICLE_REQUEST",
    GETARTICLE_SUCCESS :"GET_ARTICLE_SUCCESS",
    GETARTICLE_FAILURE :"GET_ARTICLE_FAILURE",

    GETARTICLEFORCOMPANY_REQUEST: 'GET_ARTICLE_FOR_COMPANY_REQUEST',
    GETARTICLEFORCOMPANY_SUCCESS: 'GET_ARTICLE_FOR_COMPANY_SUCCESS',
    GETARTICLEFORCOMPANY_FAILURE: 'GET_ARTICLE_FOR_COMPANY_FAILURE',

    GETARTICLEFORGROUP_REQUEST: "GET_ARTICLE_FOR_GROUP_REQUEST",
    GETARTICLEFORGROUP_SUCCESS: "GET_ARTICLE_FOR_GROUP_SUCCESS",
    GETARTICLEFORGROUP_FAILURE: "GET_ARTICLE_FOR_GROUP_FAILURE",

    SEARCHARTICLE_REQUEST: "SEARCH_ARTICLE_REQUEST",
    SEARCHARTICLE_SUCCESS: "SEARCH_ARTICLE_SUCCESS",
    SEARCHARTICLE_FAILURE: "SEARCH_ARTICLE_FAILURE",

    SEARCHARTICLEFORCMP_REQUEST: "SEARCH_ARTICLE_FOR_CMP_REQUEST",
    SEARCHARTICLEFORCMP_SUCCESS: "SEARCH_ARTICLE_FOR_CMP_SUCCESS",
    SEARCHARTICLEFORCMP_FAILURE: "SEARCH_ARTICLE_FOR_CMP_FAILURE",

    GETARTICLEFORGROUPSUPPCMP_REQUEST: "GET_ARTICLE_FOR_GROUP_SUPP_CMP_REQUEST",
    GETARTICLEFORGROUPSUPPCMP_SUCCESS: "GET_ARTICLE_FOR_GROUP_SUPP_CMP_SUCCESS",
    GETARTICLEFORGROUPSUPPCMP_FAILURE: "GET_ARTICLE_FOR_GROUP_SUPP_CMP_FAILURE"
}